import { createBrowserRouter } from "react-router-dom";

/* Pages */
import Landing from "./Pages/Landing";
import PageNotFound from "./Pages/404";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <PageNotFound />,
  },
]);

export default router;

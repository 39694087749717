import classNames from "classnames";

const Modal = ({ open, title, onClose, children }) => {
  /* https://github.com/JedWatson/classnames */
  const modalClasses = classNames(
    "fixed top-0 left-0 z-10 w-full h-full overflow-auto",
    {
      hidden: !open,
      block: open,
    }
  );
  return (
    <div className={modalClasses}>
      <div className="mx-auto mt-16 w-4/5 h-4/5  relative">
        <p className="absolute top-4 pt-2 left-8 text-base uppercase text-gray-600">
            {title}
        </p>
        <button
          type="button"
          onClick={onClose}
          class="text-slate-900 bg-gray-200  hover:bg-white-400  absolute top-4 right-4 focus:ring-4 focus:outline-none focus:ring-slate-300
           font-medium rounded-full
           button w-8 h-8 cursor-pointer select-none
           active:translate-y-2  active:[box-shadow:0_0px_0_0_#c7d2fe,0_0px_0_0_#475569]
           active:border-b-[0px]
           transition-all duration-150 [box-shadow:0_4px_0_0_#c7d2fe,0_6px_0_0_#475569]
           border-[1px] border-slate-400
           "
        >
          <span class="flex flex-col justify-center items-center h-full text-white font-bold text-lg ">
            <svg
              class="w-4 h-4 text-black"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18 18 6m0 12L6 6"
              />
            </svg>
          </span>
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;

const CTA = ({showRequestForQuoteForm}) => {
  return (
    <section id="CTA" className="p-2 md:p-4 lg:p-2">
      <div className="p-2 drop-shadow-2xl bg-white rounded-md shadow-xl border flex flex-col md:flex-row">
        <p className="p-4  major-mono text-2xl md:my-auto">
          Let's Create Something Extraordinary Together!
        </p>
        <div className="border-t md:border-l md:border-t-0 border-dos-stroke-blue md:pl-2">
          <p className="share-tech mb-4 py-4 px-2 md:leading-loose md:px-4">
            Ready to transform your digital presence/processes? Contact us today
            to discuss your project and discover how Digital Olives Studio can
            bring your ideas to life. Whether you're a startup looking to make a
            splash or an established business seeking a digital facelift, we
            have the expertise to make it happen.
          </p>
          <button
            onClick={showRequestForQuoteForm}
           className="share-tech w-full md:w-1/3 md:mb-4 rounded bg-dos-blue text-left pl-4 py-2 text-white px-1 
          md:mx-2 text-xs max-h-8 my-auto">
            Request For Quote
          </button>
        </div>
      </div>
    </section>
  );
};

export default CTA;

import { useState } from "react";

import CTA from "../components/CTA";
import WhyUs from "../components/WhyUs";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Navigation from "../../Components/Navigation";
import RequestForQuoteForm from "../components/RequestForQuote";

const Landing = () => {
  const [requestingForQuote, setRequestingForQuote] = useState(false);

  const showRequestForQuoteForm = () => {
    if (requestingForQuote) {
      setTimeout(() => {
        setRequestingForQuote(!requestingForQuote);
      }, 1000);
    } else {
      setRequestingForQuote(!requestingForQuote);
    }
  };

  return (
    <>
      <RequestForQuoteForm
        open={requestingForQuote}
        onClose={showRequestForQuoteForm}
      />
      <Navigation showRequestForm={showRequestForQuoteForm} />
      <Banner />
      <Services />
      <WhyUs />
      <CTA showRequestForQuoteForm={showRequestForQuoteForm} />
      <Footer />
    </>
  );
};

export default Landing;

import * as Yup from "yup";
import { Formik, Form } from "formik";
import {toast} from "react-toastify"

import { ReactComponent as RoleIcon } from "../../../../assets/svg/role.svg";
import { ReactComponent as MailIcon } from "../../../../assets/svg/mail.svg";
import { ReactComponent as BudgetIcon } from "../../../../assets/svg/budget.svg";
import { ReactComponent as AvatarIcon } from "../../../../assets/svg/avatar.svg";
import { ReactComponent as CompanyIcon } from "../../../../assets/svg/company.svg";

import { CheckBoxField, TelIconField, TextIconField } from "./components";
import createQuotation from "../../../../api/quotations";

const RequestQuoteForm = ({ onClose }) => {
  const notifySuccess = () => {
    toast("Successfully sent request for quotation", {
      position: "top-right",
    });
    onClose();
  };

  const notifyError = () => {
    toast.error("Could not send request for quotation", {
      position: "bottom-right",
      theme: 'light'
    });
  };

  return (
    <div className="bg-white shadow-2xl rounded-md min-w-full min-h-full py-7 md:py-10 px-4">
      <div className="bg-gray-200 mt-8 mx-1 md:mx-3 shadow-xl text-sm rounded-md p-3.5 text-gray-600 border border-gray-500">
        Please fill out the following form and we’ll be in touch with you in
        less than 24 hours.
      </div>
      <p className="text-gray-700 mt-4">Your details</p>
      <hr className="w-full h-px bg-gray-800 mb-8 px-8 mt-4"></hr>
      <div className="">
        <Formik
          initialValues={{
            role: "",
            email: "",
            lastName: "",
            firstName: "",
            companyName: "",
            phoneNumber: "",
            other: false,
            webDesign: false,
            eCommerce: false,
            UIUXDesign: false,
            cloudDeployment: false,
            bespokeDevelopment: false,
            budget: null,
            projectDetails: "",
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .max(15, "Must be 15 characters or less")
              .required("Required"),

            lastName: Yup.string()
              .max(20, "Must be 20 characters or less")
              .required("Required"),

            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            try {
              const quote = await createQuotation(values);
              if (quote.id) {
                resetForm()
                notifySuccess();
              }
            } catch (error) {
              notifyError()
            }
          }}
        >
          <Form>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <TextIconField
                icon={<AvatarIcon className="h-6 w-6" />}
                label="First Name"
                placeholder="Jane"
                name="firstName"
                type="text"
              />
              <TextIconField
                icon={<AvatarIcon className="h-6 w-6" />}
                placeholder="Doe"
                label="Last Name"
                name="lastName"
                type="text"
              />
              <TextIconField
                placeholder="jdoe@provider.com"
                icon={<MailIcon className="h-6 w-6" />}
                label="Email"
                name="email"
                type="text"
              />
              <TextIconField
                icon={<CompanyIcon className="h-6 w-6" />}
                label="Company Name"
                name="companyName"
                placeholder=""
                type="text"
              />
              <TelIconField
                label="Phone Number"
                name="phoneNumber"
                placeholder=""
                type="tel"
              />
              <TextIconField
                icon={<RoleIcon />}
                label="Role"
                name="role"
                type="text"
              />
            </div>
            <hr className="w-full h-px bg-gray-800 px-8"></hr>
            <p className="text-gray-700 my-4">I'm interested in</p>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              <CheckBoxField name="webDesign">Web Design</CheckBoxField>
              <CheckBoxField name="eCommerce">E-Commerce</CheckBoxField>
              <CheckBoxField name="UIUXDesign">UI/UX Design</CheckBoxField>
              <CheckBoxField name="cloudDeployment">
                Cloud & Deployment
              </CheckBoxField>
              <CheckBoxField name="bespokeDevelopment">
                Bespoke Development
              </CheckBoxField>
              <CheckBoxField name="other">Other</CheckBoxField>
            </div>
            <hr className="w-full mt-8 h-px bg-gray-800 px-8"></hr>
            <p className="text-gray-700 my-4">My budget for this project is</p>
            <TextIconField
              icon={<BudgetIcon className="w-6 h-6" />}
              name="budget"
              type="text"
            />
            <hr className="w-full h-px bg-gray-800 px-8"></hr>
            <p className="text-gray-700 my-4">Project details</p>
            <textarea
              name="projectDetails"
              rows="4"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write your project details here..."
            ></textarea>
            <hr className="w-full mt-8h-px bg-gray-800 px-8 mt-8"></hr>
            <button
              type="submit"
              class="mt-4 inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-blue-700 rounded-lg focus:ring-4 focus:ring-blue-200
               hover:bg-blue-800"
            >
              Request for Quote
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default RequestQuoteForm;

import RequestQuoteForm from "./Form";
import Modal from "../../../Components/Modal";

const RequestForQuoteForm = ({open, onClose}) => {
    return <Modal
        open={open}
        title="Request for Quote"
        onClose={onClose}
        children={<RequestQuoteForm onClose={onClose}/>}
    />
};

export default RequestForQuoteForm;
const WhyCard = ({ icon, title, description }) => {
  return (
    <div className="p-2 rounded-lg shadow-2xl drop-shadow-2xl md:flex md:flex-row-reverse justify-between ">
      {icon}
      <div className="p-4  md:max-w-sm lg:max-w-full">
        <p className="text-lg tracking-tight  my-4 rubik-mono md:mb-14">
          {title}
        </p>
        <p className="my-3 leading-loose  share-tech text-sm md:text-base">
          {description}
        </p>
      </div>
    </div>
  );
};

export default WhyCard;

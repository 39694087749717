import { ReactComponent as Innovation } from "../../../../assets/svg/innovation.svg";
import { ReactComponent as Collaboration } from "../../../../assets/svg/collaboration.svg";
import { ReactComponent as ResultsDrivenSolutions } from "../../../../assets/svg/results-driven.svg";

const whyUs = [
  {
    icon: <Innovation />,
    title: "Innovation at the core!",
    description: `In the ever-evolving digital landscape, we stay ahead of the curve by embracing the latest technologies and design trends. 
    Your project will benefit from cutting-edge solutions that stand out in a crowded digital space`,
  },
  {
    icon: <Collaboration />,
    title: "Collaboration",
    description: `We believe in the power of collaboration. Our team works closely with you to understand your goals, challenges, and vision, 
    ensuring that every project is a true reflection of your brand.`,
  },
  {
    icon: <ResultsDrivenSolutions />,
    title: "Results-Driven Solutions",
    description: `Your success is our success. We are dedicated to delivering solutions that not only meet your objectives but also exceed 
    your expectations. From increased engagement to higher conversion rates, we focus on measurable results.`,
  },
];

export { whyUs };

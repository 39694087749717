import { useState } from "react";

import { ReactComponent as Hamburger } from "../../assets/svg/Menu.svg";

const GetQuoteButton = ({showRequestForm}) => {
  return (
    <button 
    onClick={showRequestForm}
    className="share-tech w-3/4 rounded bg-dos-green m text-white px-1 py-1 text-xs max-h-8 my-auto">
      Get Quote
    </button>
  );
};

const NavLink = ({ path, name }) => {
  return (
    <a
      href={`/${path.toLowerCase}`}
      className="py-1.5 hover:text-blue-500 md:my-auto md:mx-2"
    >
      {name ? name : path}
    </a>
  );
};

const NavMenu = () => {
  return (
    <div className=" hidden md:flex flex-row p-2 lg:justify-between lg:w-full lg:mx-8">
      <a href="/" className="my-auto mx-2">
        Home
      </a>
      <NavLink path="Services" />
      <a href="/" className="my-auto mx-2 text-gray-400 pointer-events-none">
        Products
      </a>
      <NavLink path="about" name="About Us" />
      <NavLink path="why" name="Why Us?" />
      <NavLink path="portfolio" name="Our Work" />
    </div>
  );
};

const MobileMenu = ({ showRequestForm, toggleMobileMenu }) => {
  const showFormCloseMenu = () => {
      showRequestForm() 
      toggleMobileMenu()
  }
  return (
    <div className="p-2 shadow-xl drop-shadow-xl cursor-pointer z-10 share-tech text-sm m-2 w-84 rounded-sm ">
      <div className="flex flex-col p-2 divide-y divide-black">
        <a href="/" className="py-1.5">
          Home
        </a>
        <NavLink path="Services" />
        <a href="/" className="py-1.5 text-gray-400 pointer-events-none">
          Products
        </a>
        <NavLink path="about" name="About Us" />
        <NavLink path="why" name="Why Choose Us?" />
        <NavLink path="portfolio" name="Our Work - Portfolio" />
      </div>
      <button
        onClick={showFormCloseMenu}
        className="bg-dos-green w-full text-left px-2 py-1 rounded- text-white"
      >
        Request For Quote
      </button>
    </div>
  );
};

const Navigation = ({ showRequestForm }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

  return (
    <>
      <div className="flex flex-row justify-between border-b h-14 border-gray-700 md:h-20">
        <div className="major-mono my-auto p-2 ml-4 text-xs tracking-widest md:w-fit lg:w-4/12">
          <p className="lg:w-full">DIGITAL OLIVES STUDIO</p>
        </div>

        <NavMenu />

        <div className="hidden  lg:grid content-around  min-h-full w-48">
          <div className="lg:ml-2">
            <GetQuoteButton showRequestForm={showRequestForm} />
          </div>
        </div>

        <div className="md:hidden flex flex-row justify-between min-w-8">
          <Hamburger onClick={toggleMobileMenu} className="my-auto mx-4" />
        </div>
      </div>
      {showMobileMenu && <MobileMenu toggleMobileMenu={toggleMobileMenu} showRequestForm={showRequestForm} />}
    </>
  );
};

export default Navigation;

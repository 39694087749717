import NeonSign from "../NeonSign";
import ServiceCard from "./ServiceCard";
import { services } from "./utils/services";

const Services = () => {
  return (
    <section id="services">
      <NeonSign title={"Services"} />
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {services.map((service) => {
          return (
            <ServiceCard
              icon={service.icon}
              title={service.title}
              description={service.description}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Services;

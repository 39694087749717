import {ReactComponent as Design } from "../../../../assets/svg/design.svg";
import { ReactComponent as MobileApps } from "../../../../assets/svg/mobile-apps.svg";
import { ReactComponent as UXUIDesign } from "../../../../assets/svg/ux-ui-design.svg";
import { ReactComponent as WebDevelopment } from "../../../../assets/svg/web-development.svg";
import { ReactComponent as BrandingIdentity } from "../../../../assets/svg/branding-identity.svg";
import { ReactComponent as DeploymentAutomation } from "../../../../assets/svg/deployment-automation.svg";

const services = [
  {
    icon: <Design  className="m-auto"/>,
    title: "Digital Design",
    description: `We are committed to creating visually striking designs that leave a lasting impression. 
    From sleek and modern to bold and dynamic, we tailor our designs to match your brand identity and engage your audience effectively.`,
  },
  {
    icon: <WebDevelopment className="m-auto" />,
    title: "Web Development",
    description: `Unlock the full potential of your online presence with our top-notch web development services. 
    Whether you need a responsive website, an e-commerce platform, or a custom web application, our 
    developers leverage the latest technologies to deliver seamless and user-friendly solutions.`,
  },
  {
    icon: <MobileApps className="m-auto" />,
    title: "Mobile App Development",
    description: `Take your business on the go with our mobile app development services. We specialize in crafting innovative and 
    user-centric applications for iOS and Android platforms, ensuring your brand stays connected with your audience wherever they go.`,
  },
  {
    icon: <BrandingIdentity className="m-auto" />,
    title: "Branding & Identity",
    description: `Your brand is more than just a logo; it's a story waiting to be told. Our branding experts work closely with you 
    to define and amplify your brand identity, creating a cohesive and memorable presence across all channels.`,
  },
  {
    icon: <UXUIDesign className="m-auto" />,
    title: "UX/UI Design",
    description: `User experience (UX) and user interface (UI) are at the core of every successful digital product. Our designers focus
     on creating intuitive interfaces and seamless user journeys, ensuring your audience has an exceptional and memorable experience`,
  },
  {
    icon: <DeploymentAutomation className="m-auto" />,
    title: "Deployment & Automation",
    description: `Elevate your online presence effortlessly with our web deployment and automation services. We streamline the launch process,
    automate routine tasks, and ensure your website operates seamlessly, empowering you to focus on your core business while we handle the technical intricacies.`,
  },
];

export { services };

import NeonSign from "../NeonSign";
import WhyCard from "./WhyCard";
import { whyUs } from "./utils/why-us";

const WhyUs = () => {
  return (
    <section id="why-us" className="p-2">
      <NeonSign title={"Why Us"} />
      <div className="grid gap-4 p-2 lg:grid-rows-3 mt-4">
        {whyUs.map((why) => {
          return (
            <WhyCard
              icon={why.icon}
              title={why.title}
              description={why.description}
            />
          );
        })}
      </div>
    </section>
  );
};

export default WhyUs;

import { ReactComponent as X } from "../../../assets/svg/x.svg";
import { ReactComponent as Mail } from "../../../assets/svg/mail.svg";
import { ReactComponent as Phone } from "../../../assets/svg/phone.svg";
import { ReactComponent as LinkedIn } from "../../../assets/svg/linkedin.svg";
import { ReactComponent as BoxedLogo } from "../../../assets/svg/boxed.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/instagram.svg";

const Footer = () => {
  return (
    <footer className="md:h-60 lg:w-full">
      <hr class="h-px my-1 mx-6 mt-4 bg-dos-stroke-blue py-0.4 border-0 " />
      <div className="flex flex-col md:flex-row pt-2 justify-between  lg:w-full">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col justify-between ml-6 w-40">
              <p className="text-3xl md:text-4xl major-mono">
                DIGITAL OLIVES STUDIO
              </p>
              <a
                href="/"
                className="text-dos-blue border-gray-700 border-t-2 mt-4 pt-4 text-xs md:text-sm max-w-max"
              >
                digitalolivesstudio.com
              </a>
            </div>
            <BoxedLogo className="w-40 h-40 mr-6 md:hidden" />
          </div>
          <p class="mx-2 mt-6 tracking-widest text-sm md:text-xs text-center mb-2 md:text-left md:ml-6">
            Where Imagination meets Innovation
          </p>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="px-2 flex flex-row md:flex-col justify-between border-t-2 md:border-t-0 md:border-x-2 md:mb-2  border-gray-600 mx-4">
            <a
              href="mailto:makavura@digitalolivesstudio.com?subject=Digital Olives Studio"
              class="inline-flex items-center "
            >
              <Mail className="h-12" />
            </a>
            <a
              href="https://linkedin.com/company/digitalolivesstudio"
              class="inline-flex items-center "
            >
              <LinkedIn className="h-12" />
            </a>
            <a
              href="https://twitter.com/DigitalOStudio"
              class="inline-flex items-center "
            >
              <X className="h-12" />
            </a>
            <a
              href="https://www.instagram.com/digitalolivesstudio"
              class="inline-flex items-center "
            >
              <Instagram />
            </a>
            <a href="tel:+254736070879" class="inline-flex items-center ">
              <Phone className="h-12" />
            </a>
          </div>

          <div className="py-2 px-0-2 flex-row md:flex-col justify-between hidden md:flex">
            <a href="about" class="inline-flex items-center ">
              About Us
            </a>
            <a href="portfolio" class="inline-flex items-center ">
              Our Work
            </a>
            <a href="/" class="inline-flex items-center ">
              Products
            </a>
            <a href="services" class="inline-flex items-center ">
              Services
            </a>
            <a href="why" class="inline-flex items-center ">
              Why Us
            </a>
            <a href="/" class="inline-flex items-center ">
              Home
            </a>
          </div>
        </div>
        <BoxedLogo className="h-56 w-56 mr-6 hidden  md:block" />
      </div>
    </footer>
  );
};

export default Footer;

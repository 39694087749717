import { useField } from "formik";

const CheckBoxField = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div>
      <div class="flex items-center ps-4 border border-gray-200 rounded">
        <input
          type="checkbox"
          value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-700 rounded focus:ring-blue-500 "
          {...field}
          {...props}
        />
        <label
          for="bordered-checkbox-1"
          class="w-full py-4 ms-2 text-sm font-medium text-gray-900"
        >
          {children}
        </label>
      </div>

      {meta.touched && meta.error ? <div className="">{meta.error}</div> : null}
    </div>
  );
};

export default CheckBoxField;
import { useField } from "formik";

const TextIconField = ({ label, icon, ...props }) => {
  const [field, meta] = useField(props);
  const styles = {
    label: {
      unTouched: `block mb-2 text-sm font-medium text-gray-900 `,
      error: `block mb-2 text-sm font-medium text-red-700 dark:text-red-500`,
    },
    input: {
      unTouched: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
       focus:border-blue-500 block w-full ps-10 p-2.5`,
      error: `bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500
      focus:border-red-500 block w-full ps-10 p-2.5`,
    },
  };
  return (
    <div>
      <label
        class={meta.error ? styles.label.error : styles.label.unTouched}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <div class="relative mb-6">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          {icon}
        </div>
        <input
          type="text"
          id="input-group-1"
          class={meta.error ? styles.input.error : styles.input.unTouched}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {meta.error}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default TextIconField;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBuzV8AzbtJO4aqmY-_k4nKPThCnNZxEd8",
  authDomain: "digitalolivesstudio.firebaseapp.com",
  projectId: "digitalolivesstudio",
  storageBucket: "digitalolivesstudio.appspot.com",
  messagingSenderId: "370434799710",
  appId: "1:370434799710:web:3974ef01f8d5c57c935ccf",
  measurementId: "G-8ZBCT84M43",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, app, analytics };

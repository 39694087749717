import { ReactComponent as BoxedLogo } from "../../../assets/svg/box-logo.svg";
import { ReactComponent as BoxedSVGLogo } from "../../../assets/svg/boxed.svg";

const Banner = () => {
  return (
    <div className="md:flex md:flex-row h-full">
      <div className="flex flex-row justify-between min-w-max lg:mt-12">
        <p className="rubik-mono w-40 ml-6  min-h-full text-xl min-w-min md:mt-4 my-auto leading-7 md:text-2xl">
          We are a Digital Research, Design & Development Agency
        </p>
        <BoxedLogo className="w-40 mr-6 md:hidden" />
      </div>
      <div
        className="mx-6  share-tech md:mt-2 m-auto lg:mt-12 lg:ml-10">
        <p className="mb-4 ">
          Are you ready to elevate your digital presence to new heights? Look no
          further!
        </p>
        <p className="mb-4">
          At Digital Olives Studio, we are passionate about crafting stunning
          digital experiences that not only captivate your audience but also
          drive results.
        </p>
        <p>
          We blend creativity with technical expertise to bring your vision to
          life.
        </p>
      </div>
      <BoxedSVGLogo className="hidden lg:block h-80"/>
    </div>
  );
};

export default Banner;

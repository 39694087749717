import {  addDoc, collection } from "firebase/firestore";

import { db } from "../firebase";

const PATH = "quotation_requests";

/* 
@param {Object} quotation createQuotation
@param {string} quotation.firstName
@param {string} quotation.lastName
@param {string} quotation.email
@param {string} quotation.budget
@param {string} quotation.projectDetails
*/
const createQuotation =  (quotation) => {
    return  addDoc(collection(db, PATH), quotation);
};

export default createQuotation;
const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className="p-4">
      {icon}
      <div className="p-4 shadow-2xl drop-shadow-2xl rounded-md">
        <p className="text-lg tracking-tight my-4 rubik-mono">{title}</p>
        <p className="py-1 leading-loose share-tech text-sm md:text-base">{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;

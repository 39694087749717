import { useState } from "react";
import { Link } from "react-router-dom";

// @custom
import Footer from "../components/Footer";
import Navigation from "../../Components/Navigation";
import RequestForQuoteForm from "../components/RequestForQuote";
import { ReactComponent as Warning } from "../../assets/svg/Warning.svg";
import { ReactComponent as Logo404 } from "../../assets/svg/404 - Logo.svg";

const PageNotFound = () => {
  const [requestingForQuote, setRequestingForQuote] = useState(false);

  const showRequestForQuoteForm = () => {
    if (requestingForQuote) {
      setTimeout(() => {
        setRequestingForQuote(!requestingForQuote);
      }, 1000);
    } else {
      setRequestingForQuote(!requestingForQuote);
    }
  };
  return (
    <>
      <RequestForQuoteForm
        open={requestingForQuote}
        onClose={showRequestForQuoteForm}
      />
      <Navigation showRequestForm={showRequestForQuoteForm} />
      <div className="flex md:flex-row justify-evenly md:min-h-[600px] lg:min-h-[620px]">
        <div className="text-center my-auto h-48">
          <p className="text-8xl rubik-mono">404</p>
          <p className="text-2xl mt-4">
            <Warning className="inline my-auto mr-8 " /> Oops! Page not found
          </p>
          <span className="text-xl block mt-4">
            Would you like to go <Link className="text-blue-600 underline" to={".."}>back?</Link>
          </span>
        </div>
        <Logo404 className="lg:block  my-auto hidden" />
      </div>
      <Footer />
    </>
  );
};

export default PageNotFound;

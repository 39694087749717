const NeonSign = ({ title }) => {
  return (
    <div className="relative w-full h-28 flex flex-col border-t-2 border-dos-stroke-blue mt-8">
      <hr class="h-px my-1 absolute lg:hidden inset-x-1/4 w-8 mt-4 bg-dos-stroke-blue py-0.4 border-0  rotate-90" />
      <div className="mt-8 ">
        <div className="ml-8 md:ml-20 relative h-16 w-64 border-dos-stroke-blue border-2">
          <div className="w-4 h-4 absolute -top-2 -left-2 z-4 border-dos-stroke-blue border-2 bg-white"> </div>
          <div className="w-4 h-4 absolute -top-2 -right-2 z-4 border-dos-stroke-blue border-2 bg-white"></div>
          <div className="w-4 h-4 absolute -bottom-2 -left-2 z-4 border-dos-stroke-blue border-2 bg-white"></div>
          <div className="w-4 h-4 absolute -bottom-2 -right-2 z-4 border-dos-stroke-blue border-2 bg-white"></div>
          <p className="absolute inset-1/4 text-xl rubik-mono text-dos-stroke-blue">
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NeonSign;

import { useField } from "formik";

const TelIconField = ({ label, icon, ...props }) => {
  const [field, meta] = useField(props);
  const styles = {
    label: {
      unTouched: `block mb-2 text-sm font-medium text-gray-900 `,
      error: `block mb-2 text-sm font-medium text-red-700 dark:text-red-500`,
    },
    input: {
      unTouched: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
       focus:border-blue-500 block w-full ps-10 p-2.5`,
      error: `bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500
      focus:border-red-500 block w-full p-2.5`,
    },
  };
  return (
    <div>
      <label
        class={meta.error ? styles.label.error : styles.label.unTouched}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <div class="relative mb-6">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <svg
            class="w-4 h-4"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.72 3.16189C4.8 4.36773 5 5.54647 5.32 6.67101L3.72 8.29686C3.17333 6.67101 2.82667 4.95033 2.70667 3.16189H4.72ZM17.8667 19.4475C19 19.7726 20.16 19.9759 21.3333 20.0572V22.0759C19.5733 21.954 17.88 21.6017 16.2667 21.0598L17.8667 19.4475ZM6 0.452148H1.33333C0.6 0.452148 0 1.06184 0 1.80702C0 14.5293 10.1467 24.8399 22.6667 24.8399C23.4 24.8399 24 24.2302 24 23.485V18.7565C24 18.0113 23.4 17.4016 22.6667 17.4016C21.0133 17.4016 19.4 17.1306 17.9067 16.6293C17.7733 16.5751 17.6267 16.5616 17.4933 16.5616C17.1467 16.5616 16.8133 16.6971 16.5467 16.9545L13.6133 19.9352C9.84 17.9706 6.74667 14.8409 4.82667 11.0066L7.76 8.02589C8.13333 7.64652 8.24 7.11812 8.09333 6.64392C7.6 5.12646 7.33333 3.50061 7.33333 1.80702C7.33333 1.06184 6.73333 0.452148 6 0.452148Z"
              fill="black"
            />
          </svg>
        </div>
        <input
          type="tel"
          id="input-group-1"
          class={meta.error ? styles.input.error : styles.input.unTouched}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">
            {meta.error}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default TelIconField;
